import {
  collectComplete,
  collectFailed,
  collectPending,
} from '@oresundsbron/validators';
import {
  alt,
  chain,
  fromNullable,
  fromPredicate,
  match,
  some,
} from 'fp-ts/lib/Option';
import { flow, pipe } from 'fp-ts/lib/function';
import { z } from 'zod';

export type Complete = z.output<typeof collectComplete>;
export type Failed = z.output<typeof collectFailed>;
export type Pending = z.output<typeof collectPending>;
export type State = Complete | Failed | Pending;

const toBoolean = match(
  () => false,
  () => true
);
const isPending = fromPredicate<State, Pending>(
  (s): s is Pending => s.status === 'pending'
);
const isComplete = fromPredicate<State, Complete>(
  (s): s is Complete => s.status === 'complete'
);
const isFailed = fromPredicate<State, Failed>(
  (s): s is Failed => s.status === 'failed'
);

const nullable = (state?: State) => fromNullable(state);

export const waitingForInteraction = flow(
  nullable,
  alt(() =>
    some<State>({
      status: 'pending',
      hintCode: 'outstandingTransaction',
      orderRef: '',
    })
  ),
  chain(isPending),
  chain(fromPredicate((s) => s.hintCode === 'outstandingTransaction')),
  toBoolean
);
export const userInteraction = flow(
  nullable,
  chain(isPending),
  chain(fromPredicate((s) => s.hintCode === 'userSign')),
  toBoolean
);

export const expired = flow(
  nullable,
  chain(isFailed),
  chain(fromPredicate((s) => s.hintCode === 'expiredTransaction')),
  toBoolean
);
export const cancelled = flow(
  nullable,
  chain(isFailed),
  chain(
    fromPredicate(
      (s) => s.hintCode === 'cancelled' || s.hintCode === 'userCancel'
    )
  ),
  toBoolean
);
export const internalError = flow(
  nullable,
  chain(isFailed),
  chain(
    fromPredicate(
      (s) =>
        s.hintCode === 'internalError' ||
        s.hintCode === 'maintenance' ||
        s.hintCode === 'requestTimeout'
    )
  ),
  toBoolean
);
export const unknownError = flow(
  nullable,
  chain(isFailed),
  chain(fromPredicate((s) => s.hintCode === '')),
  toBoolean
);
export const certError = flow(
  nullable,
  chain(isFailed),
  chain(fromPredicate((s) => s.hintCode === 'certificateErr')),
  toBoolean
);
export const startFailed = flow(
  nullable,
  chain(isFailed),
  chain(fromPredicate((s) => s.hintCode === 'startFailed')),
  toBoolean
);

export const complete = flow(nullable, chain(isComplete), toBoolean);
